import React from 'react';
import {Cover} from "./components/Cover/Cover";
import {Biography} from "./components/Biography/Biography";
import {VideoContent} from "./components/VideoContent/VideoContent";
import {Musics} from "./components/Musics/Musics";

function App() {
    return (
        <>
            <Cover/>
            <Biography/>
            <VideoContent/>
            <Musics/>
        </>
    );
}

export default App;
