import './style.scss'
import GogiImage from '../../assets/images/gogi-profile.jpg'
import {IconArrowBottom} from "../../assets/icons/IconArrowBottom";




export const Cover = () => {


    const handleClick = () => {
        const element = document.getElementById('biography')
        if (element) {
            let headerOffset = 100;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }
    return <header className={'G-align-center'} style={{backgroundImage: `url('${GogiImage}')`}}>
        <div className={'G-container cover-container'}>
            <div className='cover-info'>
                <h1>Авдалян <br/>
                    Гоги Суренович</h1>
                <p>Песни, наполняющие душу радостью и гармонией </p>
            </div>
            <div className='arrow-down-info G-align-center' onClick={handleClick}>
            <span className={'G-center '}>
                <IconArrowBottom size={20}/>
            </span>
                <p>Листайте вниз</p>
            </div>
            <div  className='image-mobile' style={{backgroundImage: `url('${GogiImage}')`}}/>
        </div>

    </header>
}