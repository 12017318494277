import React, {FC, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import ClickOutside from "../click-outside";
import './style.scss'
import {CSSTransition} from "react-transition-group";
import {IconClose} from "../../assets/icons/IconClose";

interface IModal {
    isOpenModal: boolean,
    customClass?: string,

    close(): void,

    children: React.ReactNode
}

const Modal: FC<IModal> = ({customClass = '', isOpenModal = false, close, children}) => {
    useEffect(() => {
        if (isOpenModal) {
            document.body.classList.add('G-hidden')
        } else {
            document.body.classList.remove('G-hidden')
        }
    }, [isOpenModal])

    const modalRef = useRef<HTMLDivElement>(null)


    return ReactDOM.createPortal(
        <CSSTransition unmountOnExit={true} in={isOpenModal} timeout={0} classNames='fade-modal' nodeRef={modalRef}>
            <div className={`G-modal-container ${customClass}`} ref={modalRef}>
                <ClickOutside onClickOutside={close}>
                    <div className={'G-modal-content'}>
                        <div className={'G-modal-header G-justify-end'}>
                    <span onClick={close}>
                        <IconClose/>
                    </span>
                        </div>
                        <div className='G-modal-wrapper'>{children}</div>
                    </div>

                </ClickOutside>
            </div>
        </CSSTransition>
        , document.getElementById('modal') as Element
    )
}

export default Modal