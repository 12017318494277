import {useRef, useState} from "react";
import './style.scss'
import ReactPlayer from "react-player";
import videoCoverImg from '../../assets/images/video-cover.png'
import {IconPlayer} from "../../assets/icons/IconPlayer";
import goldenAgeMaster from '../../assets/video/Гоги_Авдалян-Golden_Age_Master.mp4'

export const VideoContent = () => {

    const [playing, setPlaying] = useState(false)
    const playerRef = useRef<any>(null)
    const [videoUrl, setVideoUrl] = useState('https://www.youtube.com/watch?v=nt9WCnEMdos')

    const playVideo = () => {
        setVideoUrl(goldenAgeMaster)
        setPlaying(!playing)
    }

    return <section>
        <div className='G-container'>
            <div className='vide-cover'>
                <img src={videoCoverImg} alt="video-cover"/>

                {!playing ? <>
                    <span className='video-player' onClick={playVideo}>
                <IconPlayer/>
            </span></> : <div className={'video-player-container'}>

                    <ReactPlayer width={'100%'} height={'100%'}
                                 ref={playerRef}
                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                 controls
                                 playing={playing}
                                 onEnded={()=>{
                                     setPlaying(false)
                                 }}
                                 url={videoUrl}/>
                </div>
                }

            </div>

        </div>
    </section>
}