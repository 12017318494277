import {IconSearch} from "../../assets/icons/IconSearch";

import './style.scss'
import {MusicItem} from "../MusicItem/MusicItem";
import Music1 from '../../assets/audios/01.AHCHIK_1644_MASTER_X.mp3'
import Music2 from '../../assets/audios/02.TBILISY_1644_MASTER _X.mp3'
import Music3 from '../../assets/audios/03.ISIMIDYHAR_1644_MASTER_X.mp3'
import Music4 from '../../assets/audios/04.POPURY_1644_MASTER.mp3'
import Music5 from '../../assets/audios/05.PEPO_1644_MASTER_X.mp3'
import Music6 from '../../assets/audios/06.ARMENIYAN_1644_MASTER_X.mp3'
import Music7 from '../../assets/audios/07.HAER_1644_MASTER_X.mp3'
import Music8 from '../../assets/audios/08.JAMILYA_1644_MASTER_X.mp3'
import Music9 from '../../assets/audios/09.JUREM_1644_MASTER_X.mp3'
import Music10 from '../../assets/audios/10.ARDAGAN_1644_MASTER_X.mp3'
import Music11 from '../../assets/audios/11.ZOV_GISHER_1644_MASTER_X.mp3'
import Music12 from '../../assets/audios/12.TELEBE_1644_MASTER_X.mp3'
import Music13 from '../../assets/audios/13.ARMYANSKAYA _1644_MASTER_X.mp3'
import {useState} from "react";

export const Musics = () => {
    const [activeMusic, setActiveMusic] = useState<number | null>(null)
    const musicList = [
        {
            title: 'Ays aghjika shat sirun e',
            src: Music1,
        },
        {
            title: 'Tbilis kalaks gaumardjos',
            src: Music2,
        },
        {
            title: 'Isev midikhar',
            src: Music3,
        },
        {
            title: 'Ay aghjik Gharabaghi',
            src: Music4,
        },
        {
            title: 'Pepo',
            src: Music5,
        },
        {
            title: 'Armenia',
            src: Music6,
        },
        {
            title: 'Hayer hayer',
            src: Music7,
        },
        {
            title: 'Djamilia',
            src: Music8,
        },
        {
            title: 'Jur em tsakhum',
            src: Music9,
        },
        {
            title: 'Hayer miaceq',
            src: Music10,
        },
        {
            title: 'Zov gisher',
            src: Music11,
        },
        {
            title: 'Teleba',
            src: Music12,
        },
        {
            title: 'Du indz hamar siro eraz',
            src: Music13,
        }
    ]


    const [filterList, setFilterList] = useState(musicList)

    return <section>
        <div className={'G-container'}>
            <div className={'musics-wrapper'}>
                <div className={'musics-header G-justify-between'}>
                    <h3 className='G-title'>Музыка</h3>
                    <div className={'search-input'}>
                        <label>
                            <input type="text" placeholder={'Что хотите найти?'} onChange={(e) => {
                                if (e.target.value.length) {
                                    let newList = musicList.filter((x => x.title.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
                                    setFilterList(newList)
                                } else {
                                    setFilterList(musicList)
                                }
                            }}/>
                            <span className={'search-icon'}>
                           <IconSearch/>
                       </span>
                        </label>
                    </div>
                </div>
                <div className={'musics-list'}>
                    {filterList.map((item, index) => {
                        return <MusicItem key={'music-' + index}
                                          onClick={() => {
                                              setActiveMusic(index)
                                          }}
                                          index={index}
                                          activeIndex={activeMusic}
                                          src={item.src}
                                          title={item.title}
                        />

                    })}
                </div>
            </div>
        </div>
    </section>
}