import './style.scss'
import React, {useEffect, useRef, useState} from "react";
import {IconPlayer} from "../../assets/icons/IconPlayer";
import {IconPause} from "../../assets/icons/IconPause";
import {IconDownload} from "../../assets/icons/IconDownload";

interface IProps {
    src: string,
    title: string,
    activeIndex: number| null
    onClick(): void
    index: number
}

export const MusicItem = ({src, title, activeIndex,index, onClick}: IProps) => {
    const [isPlayMusic, setIsPlayMusic] = useState(false)
    const [currLength, setCurrLength] = useState(0)
    const [length, setLength] = useState(0)
    const [progressBarStyle, setProgressbarStyle] = useState('')
    const progressRef = useRef<HTMLDivElement>(null)
    const [currentMusic, setCurrentMusic] = useState<NodeJS.Timer | null>(null)
    const [player] = useState(new Audio(src))

    function updateProgress(e: React.MouseEvent<HTMLDivElement>) {
        const target = e.target as HTMLElement
        let offset = target.getBoundingClientRect().left
        let newOffSet = e.clientX
        let newWidth = newOffSet - offset
        setProgressbarStyle(newWidth + "px")
        let secPerPx = length / target.getBoundingClientRect().width
        player.currentTime = secPerPx * newWidth
    }

    useEffect(() => {
        if (player) {
            updateTimer()
            player.addEventListener('loadedmetadata', () => {
                setLength(Math.ceil(player.duration))
            });
        }
    }, [player])


    function formatTime(s: number) {
        return Number.isNaN(s) ? '0:00' : (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }

    const playMusic = (playValue: boolean) => {
        onClick()
        if (playValue) {
            player.play()
            let currentMusicTimer = setInterval(() => {
                updateTimer()
                if (progressRef.current) {
                    let width = progressRef.current.getBoundingClientRect().width
                    let newWidth = width * (player.currentTime / Math.ceil(player.duration))
                    setProgressbarStyle(newWidth + "px")
                }
            }, 1000);
            setCurrentMusic(currentMusicTimer)
        } else {

            if (currentMusic) {
                player.currentTime = 0;
                player.pause();
                clearInterval(currentMusic)
                updateTimer()
                setProgressbarStyle('')
            }
        }
        setIsPlayMusic(playValue)
    }

    useEffect(() => {
        if (isPlayMusic && currentMusic && index!==activeIndex) {
            player.currentTime = 0;
            player.pause();
            clearInterval(currentMusic)
            updateTimer()
            setProgressbarStyle('')
            setIsPlayMusic(false)
        }

    }, [activeIndex])


    const updateTimer = () => {
        setLength(Math.ceil(player.duration))
        setCurrLength(Math.ceil(player.currentTime))
    }

    return <div className='music-item'>
        <div className='music-item-header G-align-center G-justify-between'>
            <div className={'music-item-actions G-align-center'}>
                {!isPlayMusic ? <div className={'music-item-btn G-center'} onClick={() => {
                        playMusic(true)
                    }}>
                        <div className={'music-play'}>
                            <IconPlayer size={14}/>
                        </div>
                    </div> :
                    <div className={'music-item-btn G-center'} onClick={() => {
                        playMusic(false)
                    }}>
                        <div className={'music-pause'}>
                            <IconPause size={14}/>
                        </div>
                    </div>
                }
                <h3 className={'music-title'}>{title}</h3>
            </div>
            <div className='music-item-actions G-align-center'>
                <div className={'music-item-timer G-align-center'}>
                    <div className="song-length">
                        <p>{formatTime(length - currLength)}</p>
                    </div>
                </div>
                <a download={title} className={'download-icon'} href={src}>
                    <IconDownload size={20}/>
                </a>
            </div>
        </div>

        <div className="progress">
            <div
                ref={progressRef}
                className="progress-center"
                onClick={(e) => updateProgress(e)}>
                <div className="progress-bar" style={{width: progressBarStyle}}>
                </div>
            </div>
        </div>
    </div>
}
