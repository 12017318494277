import './style.scss'
import image1 from '../../assets/images/image-1.png'
import image2 from '../../assets/images/image-2.png'
import image3 from '../../assets/images/image-3.png'
import image4 from '../../assets/images/image-4.png'
import image5 from '../../assets/images/image-5.png'
import image6 from '../../assets/images/image-6.png'
import Modal from "../Modal/Modal";
import {useState} from "react";

export const Biography = () => {
    const [openModal, setOpenModal] = useState(false)

    return <section id={'biography'}>
        <div className='G-container'>
            <div className={'biography-section'}>
                <h3 className='G-title'>Биография</h3>
                <div className='biography-mobile G-center'>
                    <button className='biography-button' onClick={() => setOpenModal(true)}>
                        Биография
                    </button>
                </div>
                <div className='biography-wrapper G-flex-column'>
                    <div className={'biography-content G-justify-between G-align-start'}>
                        <div className='biography-box'>
                            <img src={image1} alt="gogi-biography"/>
                        </div>
                        <div className='biography-box G-align-center '>
                            <p>Гоги Авдалян родился в семье музыкантов 8 мая 1935 года в Авлабаре, старинном тбилисском
                                районе, который являлся центром армянской интеллигенции. Именно там прошло его детство и
                                юность, сформировав его как личность и человека. </p>
                        </div>
                        <div className='biography-box'>
                            <img src={image5} alt="gogi-biography"/>
                        </div>
                    </div>
                    <div className={'biography-content G-justify-between G-align-start'}>
                        <div className='biography-box'>
                            <img src={image4} alt="gogi-biography"/>
                        </div>
                        <div className='biography-box G-align-center '>
                            <p>С самого детства Гоги очень любил музыку. В 10 лет его отец ушел на Великую Отечественную
                                войну. Он очень рано стал взрослым. В 19 лет его призвали в армию.Он служил в городе
                                Брянске. Он сразу стал душой всей армии и прославился как лучший игрок местной
                                футбольной команды. </p>
                        </div>
                        <div className='biography-box'>
                            <img src={image3} alt="gogi-biography"/>
                        </div>
                    </div>
                    <div className={'biography-content G-justify-between G-align-start'}>
                        <div className='biography-box'>
                            <img src={image2} alt="gogi-biography"/>
                        </div>
                        <div className='biography-box G-align-center '>
                            <p>И до армии, и после он работал на обувной фабрике. Все это время он продолжал заниматься
                                музыкой, музицировать и играть на барабане.</p>
                        </div>
                        <div className='biography-box'>
                            <img src={image6} alt="gogi-biography"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal isOpenModal={openModal} close={() => {
            setOpenModal(false)
        }} customClass={'information-card-modal'}>
            <h4 className='G-title'>Биография</h4>
            <h3>Гоги Авдалян родился в семье музыкантов 8 мая 1935 года в Авлабаре, старинном тбилисском
                районе, который являлся центром армянской интеллигенции. Именно там прошло его детство и
                юность, сформировав его как личность и человека. </h3>
            <div className={'modal-images G-flex'}>
                <img src={image1} alt="gogi-biography"/>
                <img src={image5} alt="gogi-biography"/>
            </div>
            <h3>С самого детства Гоги очень любил музыку. В 10 лет его отец ушел на Великую Отечественную
                войну. Он очень рано стал взрослым. В 19 лет его призвали в армию.Он служил в городе
                Брянске. Он сразу стал душой всей армии и прославился как лучший игрок местной
                футбольной команды. </h3>
            <div className={'modal-images G-flex'}>
                <img src={image4} alt="gogi-biography"/>
                <img src={image3} alt="gogi-biography"/>
            </div>
            <h3>И до армии, и после он работал на обувной фабрике. Все это время он продолжал заниматься музыкой,
                музицировать и играть на барабане. </h3>
            <div className={'modal-images G-flex'}>
                <img src={image2} alt="gogi-biography"/>
                <img src={image6} alt="gogi-biography"/>
            </div>
        </Modal>
    </section>
}